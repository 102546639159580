import { useState } from 'react';
import { ImagesData } from '../data/ImagesData';
import '../stylesheets/Gallery.css'
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaRegArrowAltCircleRight } from "react-icons/fa";

export default function Gallery() {
  const [index, setIndex] = useState(0)
  // const [visible, setVisible] = useState(true)

  function prevImage() {
    index === 0 ? setIndex(ImagesData.length - 1) : setIndex(index - 1)
  }

  function nextImage() {
    index === ImagesData.length - 1 ? setIndex(0) : setIndex(index + 1)
  }

  return(
    <div className='gallery'>
      <FaRegArrowAltCircleLeft onClick={prevImage} className='gallery--arrow'/>
      <a href={ImagesData[index].link} target='_blank' rel="noreferrer">
        <img src={ImagesData[index].url} alt="poem" className='gallery--image'/>
      </a>
      <FaRegArrowAltCircleRight onClick={nextImage} className='gallery--arrow'/>
    </div>
  )
}
