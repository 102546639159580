import '../stylesheets/Writings.css'
import Gallery from './Gallery'

export default function Writings() {
  return(
    <section className="section writings" id="writings">
      <div className='headings--div'>
        <span className='headings--circle blue me-3'>2</span>
        <h3 className='headings--title'>A few pieces I've written</h3>
      </div>
      <div className='writings--prose'>

        <h4 className='writings--subtitle'>MY DEBUT NOVELLA</h4>
        <div className='book--container-first'>
          <img src='/images/symbiosis_front_cover.jpeg' className="book--cover" alt="Front cover of novella Symbiosis"/>
          <div className='book--text'>
            <div className='book--text-title'>
              <h5 className='writings--piece-title'><a href="https://www.blackwaterpress.com/" className='writings--link'>Symbiosis</a></h5>
              <span className='smaller-print'>published by Blackwater Press</span>
            </div>
            <p className='book--text-synopsis'>"Life in the town of LV runs placidly and smoothly, regulated by an unspoken, but very much omnipresent System. When Monica and her daughter move into the house known as the Hovel, everyone is ready to welcome them – until it becomes apparent that they do not, and will not, blend into the well-oiled scheme. A crescendo of unrest pervades the town dragging everyone into its tidal wave, with consequences reverberating in unexpected ways. Will things ever be the same again?"</p>
            <p className='smaller-print'>Available in the US. Soon in the UK</p>
          </div>
        </div>
        <div className='book--container-second'>
          <h5 className='book--praise-title'>Awards and mentions</h5>
          <p className='book--praise-text'>Mention of honour in the <a href="https://www.storymonstersbookawards.com/royal-dragonfly-winners/royal-2023" className='writings--link'>Royal Dragonfly</a> Novel prize</p>
          <h5 className='book--praise-title'>Praise for the novella</h5>
          <p className='book--praise-text'>"With style and élan, Milagros Lasarte explores middle-class social mores in this delicious – and deliciously wicked – debut novella. It is not without compassion, however. This, coupled with Lasarte’s defining leftfield sensibility, renders the familiar yet strange organism of LV irresistible. Enormously satisfying … this is a tale that will blossom in the imagination long after it is set down." <em>– Jane McKie, author of Carnation Lily Lily Rose</em></p>
          <p className='book--praise-text'>"The cultural microcosm of the typical neighborhood is the perfect setting for Symbiosis, with a deliciously uncomfortable gander into the human tendency to create an “Us v. Them” mentality in everyday life. Debut author Milagros Lasarte nails this engrossing contemplation of societal norms with a thought-provoking twist at the end that I’m still thinking about days later. An entertaining must read from an author who is sure to be your new favorite." <em>– Leanne Kale Sparks, award-winning author of the Kendall Beck series</em></p>
        </div>

        <h4 className='writings--subtitle'>SOME MORE PROSE...</h4>
        <h5 className='writings--piece-title'>The Little Pink Box</h5>
        <p className='writings--piece-text'>A short story published by Small Leaf Press in their <em>Bitter</em> anthology.</p>
        <h5 className='writings--piece-title'>Mosaicism</h5>
        <p className='writings--piece-text'>A short story published by <a href='https://theselkie.co.uk/' className='writings--link'>The Selkie</a> in their <a href='https://theselkie.co.uk/anthologies/' className='writings--link'><em>Home</em></a> anthology.</p>
        <h5 className='writings--piece-title'>The (Beep)</h5>
        <p className='writings--piece-text'>A short story published in the <a href='https://www.fromarthursseat.com/' className='writings--link'>From Arthur’s Seat</a> anthology (2019).</p>
      </div>

      <div className='writings--poetry'>
        <h4 className='writings--subtitle'>SOME POETRY...</h4>
        <Gallery />
        <p className='writings--gallery_info'>I publish all my pieces on Instagram <a href="https://www.instagram.com/milagroslasarte/" className='writings--link bold'>@milagroslasarte</a></p>
      </div>
    </section>
  )
}
