import '../stylesheets/Landing.css'

export default function Landing() {

  return (
    <section className='landing'>
      <p className="landing--text">If you have landed on this website, you are probably wanting
          to know more about me, such as:</p>
      <img src="/images/sun.png" className='landing--image' alt="sun"/>
      <div className='landing--menu'>
        <a href="#biography" className='landing--menu-link'>
          <span className='headings--circle purple'>1</span>
          <span className='landing--menu-text'>who I am</span>
        </a>
        <a href="#writings" className='landing--menu-link'>
          <span className='headings--circle blue'>2</span>
          <span className='landing--menu-text'>what I do</span>
        </a>
        <a href="#extra" className='landing--menu-link'>
          <span className='headings--circle pink' >3</span>
          <span className='landing--menu-text'>other fascinating things</span>
        </a>
      </div>
    </section>
  )
}
