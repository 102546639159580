import '../stylesheets/Footer.css'

export default function Footer() {
  return(
    <footer className="footer">
      <div className='d-flex justify-content-around align-items-center'>
        <span className='footer--text'>© 2023 Milagros Lasarte</span>
        <span className='footer--text'>Images from rawpixel</span>
      </div>
    </footer>
  )
}
