import '../stylesheets/Biography.css'

export default function Biography() {
  return(
    <section className="section biography" id='biography'>

      {/* Title */}
      <div className='headings--div'>
        <span className='headings--circle purple'>1</span>
        <h3 className='headings--title'>Here is where I tell you interesting
          things about me in the third person</h3>
      </div>

      <img src='/images/olive-branch.png' className='biography--image' alt='olive branch'/>

      {/* Text */}
      <h4 className='biography--subtitle'>name:</h4>
      <p className='biography--text'>Milagros Lasarte</p>
      <h4 className='biography--subtitle'>self-description:</h4>
      <p className='biography--text'>an Argentinian writer, an avid reader, an opinionated daughter, a borderline maniac — it depends who asks, really</p>
      <h4 className='biography--subtitle'>life story:</h4>
      <p className='biography--text'>She moved to France at a young age and has lived there for over twenty years. Her writing, however, is all in English – a language that has become a bridge between her South American roots and her French education.</p>
      <p className='biography--text'>Her undergraduate studies at the Sorbonne focused on the linguistic and literary aspects of the English language. She then moved on to complete an MSc in Creative Writing at the University of Edinburgh.</p>
      {/* <p className='biography--text'>In 2023, life took her on a different adventure and she entered the world of programming. She now crafts different sentences with this world's enigmatic languages and is quite fond of it.</p> */}
      <p className='biography--text'>Her writing merges her three cultures together into a subtle combination of idealism and tragic irony. With a keen interest in psychology, both her fiction and poetry delve into the complexity of the human psyche as well as the tension that arises from all that is left unsaid.</p>
      <p className='biography--text'>Mastering the written word, playing with nuances, pushing the limits of imagination – these are some of the things she looks for in a good book.</p>
    </section>

  )
}
