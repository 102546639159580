export const ImagesData = [
  {
    id: 1,
    url: '/images/instagram/CHRISTMAS_1.PNG',
    link: 'https://www.instagram.com/p/CmhDEMVo4oa/'
  },
  {
    id: 2,
    url: '/images/instagram/CUENTO_2.PNG',
    link: 'https://www.instagram.com/p/Cl05og9IqSE/'
  },
  {
    id: 3,
    url: '/images/instagram/Mendicrim_1.PNG',
    link: 'https://www.instagram.com/p/ClBtr97ouWJ/'
  },
  {
    id: 4,
    url: '/images/instagram/RED_2.PNG',
    link: 'https://www.instagram.com/p/CkvquvzIoOU/'
  },
  {
    id: 5,
    url: '/images/instagram/ORIGINAL_1.PNG',
    link: 'https://www.instagram.com/p/ChC4kZAIM75/'
  }
]
