import '../stylesheets/App.css';
import Biography from './Biography'
import Extra from './Extra'
import Footer from './Footer'
import Landing from './Landing'
import Navbar from './Navbar'
import Writings from './Writings'

function App() {


  return (
    <div className="App">
      <Navbar />
      <Landing />
      <Biography />
      <Writings />
      <Extra />
      <Footer />
    </div>
  );
}

export default App;
