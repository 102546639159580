import '../stylesheets/Navbar.css'

export default function Navbar() {
  return(
    <nav className='align-items-center'>
      <div className='d-none d-lg-block d-xl-block'>
        <a href="/" className='nav--home'>Welcome home</a>
      </div>
      <div className='nav--name'>
        <div className='nav--name-title'>hello, I'm Milagros Lasarte</div>
        <h2 className='nav--name-sub'>a writer most of the time, mainly a decent human being</h2>
      </div>
      <div className='nav--socials-block'>
        <ul className='nav--socials'>
          <li>
            <a href="https://www.instagram.com/milagroslasarte" className='nav--socials-link'>instagram</a>
          </li>
          <li>
          <a href="https://twitter.com/MilagrosLste" className='nav--socials-link'>twitter</a>
          </li>
          <li>
          <a href="https://www.facebook.com/milagroslste/" className='nav--socials-link'>facebook</a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
