import '../stylesheets/Extra.css'

export default function Extra() {
  return(
    <section className="section extra" id='extra'>
      <div className='headings--div mb-4'>
        <span className='headings--circle pink me-3'>3</span>
        <h3 className='headings--title'>As for the other fascinating things...</h3>
      </div>
      <div className='extra--text_box'>
        <p className="extra--text">This humble website was designed and coded by my own hands.</p>
        <p className="extra--text">Yes, I also dabble with a bit of programming and web development.</p>
        <p className="extra--text">Well, maybe not a bit. Maybe I'm trying to make this a career.</p>
      </div>
      <div className='d-flex justify-content-center'>
        <img src="/images/eye.png" alt="design of an eye" className='extra--image'></img>
      </div>
    </section>
  )
}
